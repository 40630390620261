/* eslint-disable no-restricted-imports */

import axios from "axios"
import React, { useEffect, useState } from "react"
import LocationListForBanner from "../../app/modules/Banner/LocationListForBanner"
import CreateBannerButton from "../modules/Common/CreateNewButton.js"
import BannerList from "../modules/Banner/BannerList.js"
import ConfirmationQuestionDialog from "../../app/modules/Push/ConfirmationQuestionDialog"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import { formatDate } from "../../app/modules/Common/momentFunctions"
import { useHistory } from "react-router-dom";
import { useFormik } from "formik"
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import {
    getSuppliers
} from "../modules/Suppliers/_axios/supplierCrud.js"
import {
    getItems,
    getItemLocations,
    getBanners,
    getLocations,
    sendPushToUsers,
    sendBannerAssignToLocation,
    sendBanner,
    updateBanner,
    getBannerById,
    deleteBannerById,
} from "../modules/Customers/_axios/customerCrud"
import { FormattedMessage, injectIntl } from "react-intl"
import { FilledInput, FormControl, InputLabel, MenuItem, Select, Typography, TextField, Button } from "@material-ui/core"
import { withRouter } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { handleApiError } from "../../redux/snackbar/snackbarHandlers"
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import { he } from "date-fns/esm/locale";
import { DatePicker } from "@material-ui/pickers"
import Fab from "@material-ui/core/Fab"
import SaveIcon from "@material-ui/icons/Save"

const useStyles = makeStyles(theme => ({
    fab: {
        position: "fixed",
        bottom: theme.spacing(1),
        opacity: 0.5,
        zIndex: 10,
        "&:hover": {
            opacity: 1
        }
    },
    root: {
        minHeight: 591
    },
    textField: {
        marginTop: 16
    },
    timePicker: {
        display: 'flex',
        alignItems: 'center'
    },
    totalItems: {
        display: 'flex',
        alignItems: 'center'
    },
    bannerImage: {
        width: '50%',
        minWidth: 250,
        maxWidth: 560,
        margin: 16
    },
    desktopImage: {
        width: '50%',
        minWidth: 250,
        maxWidth: 560,
        margin: 16
    },
    mobileImage: {
        width: '50%',
        minWidth: 250,
        maxWidth: 560,
        margin: 16
    },
    optionBtn: {
        marginRight: 6
    }
})
);

const availableTypes = [
    {
        id: 0,
        name: 'Date'
    },
    {
        id: 1,
        name: 'Always'
    },
]

const actionTypes = [
    {
        id: 0,
        name: 'image'
    },
    {
        id: 1,
        name: 'URL'
    },
    {
        id: 2,
        name: 'item'
    }
];

const sendTypes = [
    {
        id: 0,
        name: 'Create'
    },
    {
        id: 1,
        name: 'Edit'
    }
];




function BannerAdminPage({ intl, ...props }) {

    const todayLabel = new Date();
    var month = (todayLabel.getMonth() + 1);
    var day = todayLabel.getDate();
    if (month < "10") month = "0" + month;
    if (day < "10") day = "0" + day;
    const todayFormat = todayLabel.getFullYear() + '-' + month + '-' + day + "T12:00:00";
    const history = useHistory();
    const dispatch = useDispatch()
    const classes = useStyles()
    const [bannerImage, setBannerImage] = useState({ BannerImage: "" })
    const [desktopImage, setDesktopImage] = useState({ DesktopImage: "" })
    const [mobileImage, setMobileImage] = useState({ MobileImage: "" })

    const [locationData, setLocationData] = useState({
        locations: [],
        selectedCount: 0,
        loading: false
    })


    const [countData, setCountData] = useState({
        count: 0,
        loading: false
    })

    const [bannersData, setBannersData] = useState({
        banners: [],
        loading: true
    })

    const [itemData, setItemData] = useState({
        items: [],
        loading: false
    })

    const [confirmationQuestionState, setConfirmationQuestionState] = useState({
        show: false
    })

    const [editMode, setEditMode] = useState({
        mode: false
    })


    const initialValues = {
        pushType: 1,
        name: '',
        description: '',
        title: '',
        customer: '',
        location: '',
        item: '',
        mealType: 1,
        actionType: 0,
        availableType: 1,
        sendType: 0,
        banner: '',
        supplier: '',
        item: '',
        active: true,
        availableFrom: todayFormat,
        availableTo: todayFormat,
    };


    function checkForError(fieldName) {
        if (formik.touched[fieldName] && formik.errors[fieldName]) {
            return true
        }
        return false
    }

    function changeSendType(type) {
        formik.setValues(initialValues)
        setBannerImage({ BannerImage: null })
        setMobileImage({ MobileImage: null })
        setDesktopImage({ setDesktopImage: null })
    }

    function renderErrors(fieldName) {
        return checkForError(fieldName) ? (
            <span style={{ color: "#F018A6" }}>{formik.errors[fieldName]}</span>
        ) : null
    }

    function sortLocations(locations) {
        return locations.sort((a, b) => a.Name.localeCompare(b.Name));
    }

    const [suppliersData, setSuppliersData] = useState({
        data: [],
        requestCount: 0,
        isLoading: true
    })

    function fetchSuppliers(cancelToken) {
        setSuppliersData({ ...suppliersData, isLoading: true })
        getSuppliers(cancelToken.token, "active")
            .then(({ data }) => {
                setSuppliersData({
                    ...suppliersData,
                    data: data,
                    isLoading: false
                })
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_SUPPLIERS"
                    })
                )
            )
    }

    function fetchItems(supplierId, cancelToken) {
        getItems(supplierId, cancelToken.token)
            .then(({ data }) => {
                setItemData({ items: data, loading: false })
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "asdasd"
                    })
                )
            )
    }

    function fetchFullListLocations(cancelToken) {
        getLocations(cancelToken.token)
            .then(({ data }) => {
                const defaultLocations = setLocationsAsSelectedByDefault(data);
                setLocationData({
                    locations: sortLocations(defaultLocations),
                    selectedCount: defaultLocations.length,
                    loading: false
                })
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_SUPPLIERS"
                    })
                )
            )
    }

    function setLocationSelected(data) {
        const newLocationData = locationData;
        const selectedBanner = bannersData.banners.filter(banner => banner.id == data)
        if (selectedBanner[0].locations == null) {
            newLocationData.locations.map(location => {
                location.IsSelected = false
            })
            newLocationData.selectedCount = 0;
        } else {
            newLocationData.locations.map(location => {
                if (selectedBanner[0].locations.includes(location.LocationID)) {
                    location.IsSelected = true
                    newLocationData.selectedCount++
                } else if (location.IsSelected) {
                    location.IsSelected = false
                    newLocationData.selectedCount--
                }
            })
        }
        setLocationData({
            locations: newLocationData.locations,
            selectedCount: newLocationData.selectedCount,
            loading: false
        })

    }

    function setLocationsAsSelectedByDefault(data) {
        return data.map(item => {
            return { ...item, IsSelected: true }
        })
    }

    function fetchMealAndItemLocations(itemID, cancelToken) {
        getItemLocations(formik.values.mealType, itemID, cancelToken.token)
            .then(({ data }) => {
                const defaultLocations = setLocationsAsSelectedByDefault(data);
                setLocationData({
                    locations: sortLocations(defaultLocations),
                    selectedCount: defaultLocations.length,
                    loading: false
                })
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_SUPPLIERS"
                    })
                )
            )
    }

    function setEditBannerData(bannerId) {
        getBannerById(bannerId).then(({ data }) => {
            var values = {};
            const cancelToken = axios.CancelToken.source()
            var editSupplierId = "";
            if (data.item) {
                fetchItems(data.item.SupplierID, cancelToken)
                editSupplierId = data.item.SupplierID;
            }
            if (data.availableFrom) {
                values = {
                    name: data.name,
                    description: data.description,
                    title: data.title,
                    item: data.itemID,
                    actionType: data.type,
                    sendType: 1,
                    supplier: editSupplierId,
                    url: data.url,
                    banner: bannerId,
                    active: data.active,
                    availableType: 0,
                    availableFrom: new Date(data.availableFrom),
                    availableTo: new Date(data.availableTo),
                    bannerImageUrl: data.bannerImageUrl,
                    mobileImageUrl: data.mobileImageUrl,
                    desktopImageUrl: data.desktopImageUrl
                }
            }
            else
                values = {
                    name: data.name,
                    description: data.description,
                    title: data.title,
                    item: data.itemID,
                    supplier: editSupplierId,
                    actionType: data.type,
                    sendType: 1,
                    url: data.url,
                    banner: bannerId,
                    active: data.active,
                    availableType: 1,
                    bannerImageUrl: data.bannerImageUrl,
                    mobileImageUrl: data.mobileImageUrl,
                    desktopImageUrl: data.desktopImageUrl
                }
            formik.setValues(values)
        })
    }


    function handleGetActive() {
        if (formik.values.active) { return "0" }
        else return "1"
    }

    function checkValuesEdit() {
        var flag = true;
        if (formik.values.name && (formik.values.bannerImage || formik.values.bannerImageUrl) && (formik.values.mobileImage || formik.values.bannerImageUrl)) {
            if (formik.values.actionType == 0 && (formik.values.desktopImage || formik.values.desktopImageUrl)) flag = false
            if (formik.values.actionType == 1 && formik.values.url) flag = false
            if (formik.values.actionType == 2 && formik.values.item) flag = false
        }
        if (!(formik.values.availableFrom && formik.values.availableTo)) {
            if (formik.values.availableType == 0) flag = true
        }
        return flag
    }

    function getData() {
        var data = new FormData()
        if (formik.values.name) {
            data.append("name", formik.values.name)
        }
        if (formik.values.description) {
            data.append("description", formik.values.description)
        }
        if (formik.values.title) {
            data.append("title", formik.values.title)
        }
        if (formik.values.url) {
            data.append("url", formik.values.url)
        }
        data.append("type", formik.values.actionType)
        if (formik.values.bannerImage) {
            data.append("BannerImage", formik.values.bannerImage)
        }
        if (formik.values.desktopImage) {
            data.append("desktopImage", formik.values.desktopImage)
        }
        if (formik.values.mobileImage) {
            data.append("mobileImage", formik.values.mobileImage)
        }
        if (formik.values.bannerImageUrl) {
            data.append("BannerImageUrl", formik.values.bannerImageUrl)
        }
        if (formik.values.desktopImageUrl) {
            data.append("desktopImageUrl", formik.values.desktopImageUrl)
        }
        if (formik.values.mobileImageUrl) {
            data.append("mobileImageUrl", formik.values.mobileImageUrl)
        }
        if (formik.values.url) {
            data.append("url", formik.values.url)
        }
        if (formik.values.item) {
            data.append("itemID", formik.values.item)
        }
        data.append("active", formik.values.active)
        if (formik.values.availableType == 0) {
            if (formik.values.availableFrom) {
                var date = new Date(formik.values.availableFrom);
                if (formik.values.sendType == 1) date.setDate(date.getDate() + 1)
                data.append("availableFrom", date.toISOString().split('T')[0])
            }
            if (formik.values.availableTo) {
                var date = new Date(formik.values.availableTo);
                if (formik.values.sendType == 1) date.setDate(date.getDate() + 1)
                data.append("availableTo", date.toISOString().split('T')[0])
            }
        }
        return data;
    }

    function handleSendLocationSubmit(bannerId) {
        const cancelToken = axios.CancelToken.source()
        //HERE UPDATE LOCATION
        const data = {
            "bannerId": bannerId,
            "bannerLocations": []
        }
        locationData.locations.filter(item => item.IsSelected)
            .map(item => data.bannerLocations.push({ "locationId": item.LocationID }));
        sendBannerAssignToLocation(data, cancelToken.token)
            .then(({ data }) => {
                // handlePushReportSubmit();
                updateData(cancelToken)
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_SUPPLIERS"
                    })
                )
            )
    }


    function handleDeleteSubmit(banner) {
        const cancelToken = axios.CancelToken.source()
        deleteBannerById(banner.id)
            .then(({ data }) => {
                updateData(cancelToken)
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_DELETE_BANNERS"
                    })
                )
            )

    }

    function handleCreateSubmit() {
        const cancelToken = axios.CancelToken.source()
        const data = getData();
        sendBanner(data, cancelToken.token)
            .then(({ data }) => {
                handleSendLocationSubmit(data.id);
                updateData(cancelToken)
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_CREATE_BANNER"
                    })
                )
            )
    }
    function handleEditSubmit() {
        const cancelToken = axios.CancelToken.source()
        const data = getData();
        updateBanner(data, formik.values.banner, cancelToken.token)
            .then(({ data }) => {
                //handlePushReportSubmit();
                handleSendLocationSubmit(formik.values.banner);
                updateData(cancelToken)
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_CREATE_BANNER"
                    })
                )
            )
    }


    function handleCloseConfirmationQuestionPanel(event) {
        setConfirmationQuestionState({ show: false })
    }

    function handleSendSubmit() {
        const cancelToken = axios.CancelToken.source()
        const locationIds = locationData.locations.filter(item => item.IsSelected)
            .map(item => item.LocationID);
        const data = getData();
        data.append("locationIds", locationIds)
        return sendPushToUsers(data, cancelToken.token)
    }


    const validationSchema = Yup.object().shape({
    })

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values, { setSubmitting, setFieldError, resetForm }) => {
            const {
            } = values
        }
    })

    function getBannersData() {
        setBannersData({ banners: [], loading: true })
        const cancelToken = axios.CancelToken.source()
        getBanners(cancelToken.token)
            .then(({ data }) => {
                setBannersData({ banners: data, loading: false })
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_SUPPLIERS"
                    })
                )
            )
    }

    function updateData(cancelToken) {
        getBannersData();
        fetchSuppliers(cancelToken)
    }

    useEffect(() => {
        const cancelToken = axios.CancelToken.source()
        updateData(cancelToken)
        locationDataUpdate();
        return () => cancelToken.cancel()
    }, [])

    function locationDataUpdate() {
        const cancelToken = axios.CancelToken.source()
        setLocationData({ locations: [], selectedCount: 0, loading: true })
        setCountData({ sendCount: 0, loading: false });
        fetchFullListLocations(cancelToken)
        return () => cancelToken.cancel()
    }

    function handleLocationSelection() {
        const isSelected = locationData.locations.some(item => item.IsSelected);
        locationData.locations.forEach(item => {
            item.IsSelected = !isSelected;
        })
        setLocationData({
            locations: sortLocations(locationData.locations),
            selectedCount: locationData.locations.filter(item => item.IsSelected).length,
            loading: false
        })
        setCountData({ sendCount: 0, loading: false });
    }
   

    function updateLocation(locationID) {
        const itemLocation =
            locationData.locations.filter(item => item.LocationID == locationID);
        if (itemLocation[0]) {
            itemLocation[0].IsSelected = !itemLocation[0].IsSelected;
        }
        setLocationData({
            locations: sortLocations(locationData.locations),
            selectedCount: locationData.locations.filter(item => item.IsSelected).length,
            loading: false
        })
        setCountData({ sendCount: 0, loading: false });
    }

    function renderFields() {
        return (
            <div style={{ minHeight: 136.88 }}>


                <LocationListForBanner
                    locationsData={locationData}
                    handleSelection={handleLocationSelection}
                    setSelected={updateLocation} />
              

            </div>
        )

    }

    function handleEditBanner(banner) {
        setEditBannerData(banner.id)
        setLocationSelected(banner.id);
        formik.setFieldValue("banner", banner.id)
        setEditMode({ mode: true })
    }

    function handleBackToList() {
        const cancelToken = axios.CancelToken.source()
        setEditMode({ mode: false })
        formik.setFieldValue("sendType", 0);
        fetchFullListLocations(cancelToken)
        changeSendType(0)
    }
    function handleCreateBanner() {
        setEditMode({ mode: true })
    }


    function handleSaveButton() {
        if (formik.values.sendType == 0) {
            handleCreateSubmit()
            handleBackToList()
        } else {
            handleEditSubmit();
            handleBackToList()
        }
    }



    function handlePushReportSubmit() {
        history.push('/reports-push');
    }
    if (editMode.mode) return (
        <>
            <Fab
                color="secondary"
                aria-label="Create new"
                className={classes.fab}
                disabled={checkValuesEdit()}
                onClick={handleSaveButton}
            >
                <SaveIcon />
            </Fab>
            <button
                type="button"
                onClick={handleBackToList}
                className={`btn btn-primary font-weight-bold mr-2`}
            >
                {
                    intl.formatMessage({
                        id: "ADMIN_USERS.CREATE_FORM.BACK_BUTTON"
                    })
                }
            </button>

            <ConfirmationQuestionDialog
                show={confirmationQuestionState.show}
                onClose={handleCloseConfirmationQuestionPanel}
                sendRequest={handleSendSubmit}
                questionData={{
                    Question: intl.formatMessage({
                        id: "ADMIN_DETAIL.LABELS.PUSH_CONFIRMATION"
                    }, { X: countData.sendCount })
                }}
                extraAction={handlePushReportSubmit}
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={he}>
                <div>
                    {
                        !(itemData.loading || locationData.loading || bannersData.loading) &&
                        <div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="card card-custom flex-row justify-content-center gutter-b">
                                        <div className="card-body flex-grow-0 full-width">


                                            {

                                                (
                                                    renderFields()
                                                )
                                            }


                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-6">

                                    {
                                        formik.values.sendType === 0 && (
                                        <div className="card card-custom flex-row justify-content-center gutter-b">
                                            <div className="card-body">

                                                <FormControl
                                                    variant="outlined"
                                                    className={classes.textField}
                                                    fullWidth
                                                >
                                                    <TextField
                                                        id="name"
                                                        name="name"
                                                        label={intl.formatMessage({
                                                            id: "ADMIN_DETAIL.LABELS.NAME"
                                                        })}
                                                        className={classes.textField}
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        {...formik.getFieldProps("name")}
                                                        error={checkForError("name")}
                                                        inputProps={{
                                                            className: classes.textRight
                                                        }}
                                                        dir="ltr"
                                                    />
                                                </FormControl>
                                                {renderErrors("name")}

                                                <FormControl
                                                    variant="outlined"
                                                    className={classes.textField}
                                                    fullWidth
                                                >
                                                    <TextField
                                                        id="description"
                                                        name="description"
                                                        label={intl.formatMessage({
                                                            id: "ADMIN_DETAIL.LABELS.DESCRIPTION"
                                                        })}
                                                        className={classes.textField}
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        {...formik.getFieldProps("description")}
                                                        error={checkForError("description")}
                                                        inputProps={{
                                                            className: classes.textRight
                                                        }}
                                                        dir="ltr"
                                                    />
                                                </FormControl>
                                                {renderErrors("description")}


                                                    <FormControl
                                                        variant="outlined"
                                                        className={classes.textField}
                                                        fullWidth
                                                    >
                                                        <TextField
                                                            id="title"
                                                            name="title"
                                                            label={intl.formatMessage({
                                                                id: "ADMIN_DETAIL.LABELS.TITLE"
                                                            })}
                                                            className={classes.textField}
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            {...formik.getFieldProps("title")}
                                                            error={checkForError("title")}
                                                            inputProps={{
                                                                className: classes.textRight
                                                            }}
                                                            dir="ltr"
                                                        />
                                                    </FormControl>
                                                    {renderErrors("title")}

                                                <div className={classes.textField}>
                                                    <div>
                                                        {
                                                            bannerImage.BannerImage && <Button variant="contained" component="label"
                                                                className={classes.optionBtn}
                                                                onClick={() => {
                                                                    formik.setFieldValue("bannerImage", null)
                                                                    setBannerImage({ BannerImage: null })
                                                                }}>
                                                                {intl.formatMessage({
                                                                    id: "ADMIN_DETAIL.LABELS.REMOVE_DESKTOP_IMAGE"
                                                                })}
                                                            </Button>
                                                        }

                                                        <Button variant="contained" component="label">
                                                            {intl.formatMessage({
                                                                id: "ADMIN_DETAIL.LABELS.DESKTOP_IMAGE"
                                                            })}
                                                            <input
                                                                id="image-file"
                                                                name="bannerImage"
                                                                type="file"
                                                                accept="image/jpeg, image/jpg, image/png"
                                                                onChange={event => {
                                                                    if (event.currentTarget && event.currentTarget.files[0]) {
                                                                        formik.setFieldValue("bannerImage", event.currentTarget.files[0])
                                                                        var reader = new FileReader();
                                                                        reader.onloadend = (readerEvt) => {
                                                                            var binaryString = readerEvt.target.result;
                                                                            setBannerImage({ BannerImage: binaryString });
                                                                        };
                                                                        reader.readAsDataURL(event.currentTarget.files[0]);
                                                                    }
                                                                }}
                                                                hidden
                                                            />
                                                        </Button>
                                                    </div>

                                                    {
                                                        bannerImage.BannerImage &&
                                                        <div>
                                                            <img src={bannerImage.BannerImage}
                                                                className={classes.bannerImage} />
                                                        </div>
                                                    }
                                                </div>

                                                <div className={classes.textField}>
                                                    <div>
                                                        {
                                                            mobileImage.MobileImage && <Button variant="contained" component="label"
                                                                className={classes.optionBtn}
                                                                onClick={() => {
                                                                    formik.setFieldValue("mobileImage", null)
                                                                    setMobileImage({ MobileImage: null })
                                                                }}>
                                                                {intl.formatMessage({
                                                                    id: "ADMIN_DETAIL.LABELS.REMOVE_MOBILE_IMAGE"
                                                                })}
                                                            </Button>
                                                        }

                                                        <Button variant="contained" component="label">
                                                            {intl.formatMessage({
                                                                id: "ADMIN_DETAIL.LABELS.MOBILE_IMAGE"
                                                            })}
                                                            <input
                                                                id="image-file"
                                                                name="mobileImage"
                                                                type="file"
                                                                accept="image/jpeg, image/jpg, image/png"
                                                                onChange={event => {
                                                                    if (event.currentTarget && event.currentTarget.files[0]) {
                                                                        formik.setFieldValue("mobileImage", event.currentTarget.files[0])
                                                                        var reader = new FileReader();
                                                                        reader.onloadend = (readerEvt) => {
                                                                            var binaryString = readerEvt.target.result;
                                                                            setMobileImage({ MobileImage: binaryString });
                                                                        };
                                                                        reader.readAsDataURL(event.currentTarget.files[0]);
                                                                    }
                                                                }}
                                                                hidden
                                                            />
                                                        </Button>
                                                    </div>

                                                    {
                                                        mobileImage.MobileImage &&
                                                        <div>
                                                            <img src={mobileImage.MobileImage}
                                                                className={classes.mobileImage} />
                                                        </div>
                                                    }
                                                </div>



                                                <FormControl
                                                    fullWidth={true}
                                                    className={classes.textField}
                                                >
                                                    <InputLabel htmlFor="role-select">
                                                        <FormattedMessage id="ADMIN_USERS.CREATE_FORM.ACTION_TYPE_FIELD.LABEL" />
                                                    </InputLabel>
                                                    <Select
                                                        {...formik.getFieldProps("actionType")}
                                                        onChange={event => {
                                                            formik.setFieldValue("actionType", event.target.value);
                                                            //defaultImageSet(formik.values.item);
                                                        }}
                                                        input={<FilledInput name="actionType" id="actionType-select" />}
                                                    >

                                                        {actionTypes.map(actionType => (
                                                            <MenuItem
                                                                key={actionType.id}
                                                                value={actionType.id}
                                                            >
                                                                {actionType.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>

                                                {
                                                    formik.values.actionType === 0 &&
                                                    <div className={classes.textField}>
                                                        <div>
                                                            {
                                                                desktopImage.DesktopImage && <Button variant="contained" component="label"
                                                                    className={classes.optionBtn}
                                                                    onClick={() => {
                                                                        formik.setFieldValue("desktopImage", null)
                                                                        setDesktopImage({ DesktopImage: null })
                                                                    }}>
                                                                    {intl.formatMessage({
                                                                        id: "ADMIN_DETAIL.LABELS.REMOVE_IMAGE"
                                                                    })}
                                                                </Button>
                                                            }

                                                            <Button variant="contained" component="label">
                                                                {intl.formatMessage({
                                                                    id: "ADMIN_DETAIL.LABELS.IMAGE"
                                                                })}
                                                                <input
                                                                    id="image-file"
                                                                    name="desktopImage"
                                                                    type="file"
                                                                    accept="image/jpeg, image/jpg, image/png"
                                                                    onChange={event => {
                                                                        if (event.currentTarget && event.currentTarget.files[0]) {
                                                                            formik.setFieldValue("desktopImage", event.currentTarget.files[0])
                                                                            var reader = new FileReader();
                                                                            reader.onloadend = (readerEvt) => {
                                                                                var binaryString = readerEvt.target.result;
                                                                                setDesktopImage({ DesktopImage: binaryString });
                                                                            };
                                                                            reader.readAsDataURL(event.currentTarget.files[0]);
                                                                        }

                                                                    }}
                                                                    hidden
                                                                />
                                                            </Button>
                                                        </div>

                                                        {
                                                            desktopImage.DesktopImage &&
                                                            <div>
                                                                <img src={desktopImage.DesktopImage}
                                                                    className={classes.desktopImage} />
                                                            </div>
                                                        }
                                                    </div>
                                                }

                                                {
                                                    formik.values.actionType === 1 &&
                                                    <>
                                                        <FormControl
                                                            variant="outlined"
                                                            className={classes.textField}
                                                            fullWidth
                                                        >
                                                            <TextField
                                                                id="url"
                                                                name="url"
                                                                label={intl.formatMessage({
                                                                    id: "ADMIN_DETAIL.LABELS.URL"
                                                                })}
                                                                className={classes.textField}
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                                {...formik.getFieldProps("url")}
                                                                error={checkForError("url")}
                                                                inputProps={{
                                                                    className: classes.textRight
                                                                }}
                                                                dir="ltr"
                                                            />
                                                        </FormControl>
                                                        {renderErrors("url")}
                                                    </>
                                                }
                                                {
                                                    formik.values.actionType === 2 &&
                                                    <div className={classes.textField}>
                                                        <FormControl
                                                            fullWidth={true}
                                                            className={classes.textField}
                                                        >
                                                            <InputLabel htmlFor="role-select">
                                                                <FormattedMessage id="ADMIN_USERS.CREATE_FORM.SUPPLIERS" />
                                                            </InputLabel>
                                                            <Select
                                                                {...formik.getFieldProps("supplier")}
                                                                onChange={event => {
                                                                    formik.setFieldValue("supplier", event.target.value)
                                                                    const cancelToken = axios.CancelToken.source()
                                                                    fetchItems(event.target.value, cancelToken)
                                                                    return () => cancelToken.cancel()
                                                                }}
                                                                input={<FilledInput name="supplier" id="supplier-select" />}
                                                            >
                                                                {
                                                                    suppliersData.data.map(supplier => (
                                                                        <MenuItem
                                                                            key={supplier.SupplierID}
                                                                            value={supplier.SupplierID}
                                                                        >
                                                                            {supplier.Name}
                                                                        </MenuItem>
                                                                    ))
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                        {
                                                            formik.values.supplier &&
                                                            <div>
                                                                <FormControl
                                                                    fullWidth={true}
                                                                    className={classes.textField}
                                                                >
                                                                    <InputLabel htmlFor="role-select">
                                                                        <FormattedMessage id="ADMIN_USERS.CREATE_FORM.ITEMS" />
                                                                    </InputLabel>
                                                                    <Select
                                                                        {...formik.getFieldProps("item")}
                                                                        onChange={event => {
                                                                            formik.setFieldValue("item", event.target.value)
                                                                        }}
                                                                        input={<FilledInput name="item" id="item-select" />}
                                                                    >
                                                                        {
                                                                            itemData.items.map(item => (
                                                                                <MenuItem
                                                                                    key={item.ItemID}
                                                                                    value={item.ItemID}
                                                                                >
                                                                                    {item.Name}
                                                                                </MenuItem>
                                                                            ))
                                                                        }
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                        }

                                                    </div>

                                                }

                                                <FormControl
                                                    fullWidth={true}
                                                    className={classes.textField}
                                                >
                                                    <RadioGroup
                                                        defaultValue={handleGetActive()}
                                                        name="radio-buttons-group"
                                                        onChange={event => {
                                                            if (event.target.value == 0) { formik.setFieldValue("active", true) }
                                                            else formik.setFieldValue("active", false)
                                                        }}
                                                    >
                                                    <FormControlLabel value="0" control={<Radio />} label="active" />
                                                    <FormControlLabel value="1" control={<Radio />} label="Not active" />
                                                    </RadioGroup>
                                                </FormControl>
                                                <FormControl
                                                    fullWidth={true}
                                                    className={classes.textField}
                                                >
                                                    <InputLabel htmlFor="role-select">
                                                        <FormattedMessage id="ADMIN_USERS.CREATE_FORM.AVAILABLE_FIELD.LABEL" />
                                                    </InputLabel>
                                                    <Select
                                                        {...formik.getFieldProps("availableType")}
                                                        onChange={event => {
                                                            formik.setFieldValue("availableType", event.target.value)
                                                        }}
                                                        input={<FilledInput name="availableType" id="active-select" />}
                                                    >
                                                        {availableTypes.map(availableType => (
                                                            <MenuItem
                                                                key={availableType.id}
                                                                value={availableType.id}
                                                            >
                                                                {availableType.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                {
                                                    formik.values.availableType == 0 &&
                                                    <>
                                                        availableFrom
                                                        <div className={classes.timePicker}>


                                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={he}>
                                                                <DatePicker
                                                                    autoOk
                                                                    format="dd/MM/yyyy"
                                                                    value={formik.values.availableFrom}
                                                                    onChange={value => {
                                                                        formik.setFieldValue("availableFrom", value)
                                                                    }}
                                                                    label={intl.formatMessage({
                                                                        id: "DATE_FIELD.LABEL"
                                                                    })}
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                        </div>
                                                        availableTo
                                                        <div className={classes.timePicker}>
                                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={he}>
                                                                <DatePicker
                                                                    autoOk
                                                                    format="dd/MM/yyyy"
                                                                    value={formik.values.availableTo}
                                                                    onChange={value => {
                                                                        formik.setFieldValue("availableTo", value)
                                                                    }}
                                                                    label={intl.formatMessage({
                                                                        id: "DATE_FIELD.LABEL"
                                                                    })}
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                        </div>

                                                    </>
                                                }
                                            </div>
                                        </div>)
                                    }
                                    {
                                        formik.values.sendType === 1 && (<div className="card card-custom gutter-b">

                                            <div className="card-body">

                                                <FormControl
                                                    variant="outlined"
                                                    className={classes.textField}
                                                    fullWidth
                                                >
                                                    <TextField
                                                        id="name"
                                                        name="name"
                                                        label={intl.formatMessage({
                                                            id: "ADMIN_DETAIL.LABELS.NAME"
                                                        })}
                                                        className={classes.textField}
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        {...formik.getFieldProps("name")}
                                                        error={checkForError("name")}
                                                        inputProps={{
                                                            className: classes.textRight
                                                        }}
                                                        dir="ltr"
                                                    />
                                                </FormControl>
                                                {renderErrors("name")}

                                                <FormControl
                                                    variant="outlined"
                                                    className={classes.textField}
                                                    fullWidth
                                                >
                                                    <TextField
                                                        id="description"
                                                        name="description"
                                                        label={intl.formatMessage({
                                                            id: "ADMIN_DETAIL.LABELS.DESCRIPTION"
                                                        })}
                                                        className={classes.textField}
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        {...formik.getFieldProps("description")}
                                                        error={checkForError("description")}
                                                        inputProps={{
                                                            className: classes.textRight
                                                        }}
                                                        dir="ltr"
                                                    />
                                                </FormControl>
                                                {renderErrors("description")}

                                                <FormControl
                                                    variant="outlined"
                                                    className={classes.textField}
                                                    fullWidth
                                                >
                                                    <TextField
                                                        id="title"
                                                        name="title"
                                                        label={intl.formatMessage({
                                                            id: "ADMIN_DETAIL.LABELS.TITLE"
                                                        })}
                                                        className={classes.textField}
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        {...formik.getFieldProps("title")}
                                                        error={checkForError("title")}
                                                        inputProps={{
                                                            className: classes.textRight
                                                        }}
                                                        dir="ltr"
                                                    />
                                                </FormControl>
                                                {renderErrors("title")}

                                                <div className={classes.textField}>
                                                    <div>
                                                        {
                                                            bannerImage.BannerImage && <Button variant="contained" component="label"
                                                                className={classes.optionBtn}
                                                                onClick={() => {
                                                                    formik.setFieldValue("bannerImage", null)
                                                                    setBannerImage({ BannerImage: null })
                                                                }}>
                                                                {intl.formatMessage({
                                                                    id: "ADMIN_DETAIL.LABELS.REMOVE_DESKTOP_IMAGE"
                                                                })}
                                                            </Button>
                                                        }

                                                        <Button variant="contained" component="label">
                                                            {intl.formatMessage({
                                                                id: "ADMIN_DETAIL.LABELS.DESKTOP_IMAGE"
                                                            })}
                                                            <input
                                                                id="image-file"
                                                                name="bannerImage"
                                                                type="file"
                                                                accept="image/jpeg, image/jpg, image/png"
                                                                onChange={event => {
                                                                    if (event.currentTarget && event.currentTarget.files[0]) {
                                                                        formik.setFieldValue("bannerImage", event.currentTarget.files[0])
                                                                        var reader = new FileReader();
                                                                        reader.onloadend = (readerEvt) => {
                                                                            var binaryString = readerEvt.target.result;
                                                                            setBannerImage({ BannerImage: binaryString });
                                                                        };
                                                                        reader.readAsDataURL(event.currentTarget.files[0]);
                                                                    }
                                                                }}
                                                                hidden
                                                            />
                                                        </Button>
                                                    </div>

                                                    {
                                                        formik.values.bannerImageUrl && !bannerImage.BannerImage &&
                                                        <div>
                                                            <img src={formik.values.bannerImageUrl}
                                                                className={classes.bannerImage} />
                                                        </div>
                                                    }
                                                    {
                                                        bannerImage.BannerImage &&
                                                        <div>
                                                            <img src={bannerImage.BannerImage}
                                                                className={classes.bannerImage} />
                                                        </div>
                                                    }
                                                </div>

                                                <div className={classes.textField}>
                                                    <div>
                                                        {
                                                            mobileImage.MobileImage && <Button variant="contained" component="label"
                                                                className={classes.optionBtn}
                                                                onClick={() => {
                                                                    formik.setFieldValue("mobileImage", null)
                                                                    setMobileImage({ MobileImage: null })
                                                                }}>
                                                                {intl.formatMessage({
                                                                    id: "ADMIN_DETAIL.LABELS.REMOVE_MOBILE_IMAGE"
                                                                })}
                                                            </Button>
                                                        }

                                                        <Button variant="contained" component="label">
                                                            {intl.formatMessage({
                                                                id: "ADMIN_DETAIL.LABELS.MOBILE_IMAGE"
                                                            })}
                                                            <input
                                                                id="image-file"
                                                                name="mobileImage"
                                                                type="file"
                                                                accept="image/jpeg, image/jpg, image/png"
                                                                onChange={event => {
                                                                    if (event.currentTarget && event.currentTarget.files[0]) {
                                                                        formik.setFieldValue("mobileImage", event.currentTarget.files[0])
                                                                        var reader = new FileReader();
                                                                        reader.onloadend = (readerEvt) => {
                                                                            var binaryString = readerEvt.target.result;
                                                                            setMobileImage({ MobileImage: binaryString });
                                                                        };
                                                                        reader.readAsDataURL(event.currentTarget.files[0]);
                                                                    }
                                                                }}
                                                                hidden
                                                            />
                                                        </Button>
                                                    </div>

                                                    {
                                                        formik.values.mobileImageUrl && !mobileImage.MobileImage &&
                                                        <div>
                                                            <img src={formik.values.mobileImageUrl}
                                                                className={classes.mobileImage} />
                                                        </div>
                                                    }
                                                    {
                                                        mobileImage.MobileImage &&
                                                        <div>
                                                            <img src={mobileImage.MobileImage}
                                                                className={classes.mobileImage} />
                                                        </div>
                                                    }
                                                </div>



                                                <FormControl
                                                    fullWidth={true}
                                                    className={classes.textField}
                                                >
                                                    <InputLabel htmlFor="role-select">
                                                        <FormattedMessage id="ADMIN_USERS.CREATE_FORM.ACTION_TYPE_FIELD.LABEL" />
                                                    </InputLabel>
                                                    <Select
                                                        {...formik.getFieldProps("actionType")}
                                                        onChange={event => {
                                                            formik.setFieldValue("actionType", event.target.value);
                                                            //defaultImageSet(formik.values.item);
                                                        }}
                                                        input={<FilledInput name="actionType" id="actionType-select" />}
                                                    >

                                                        {actionTypes.map(actionType => (
                                                            <MenuItem
                                                                key={actionType.id}
                                                                value={actionType.id}
                                                            >
                                                                {actionType.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>

                                                {
                                                    formik.values.actionType === 0 &&
                                                    <div className={classes.textField}>
                                                        <div>
                                                            {
                                                                desktopImage.DesktopImage && <Button variant="contained" component="label"
                                                                    className={classes.optionBtn}
                                                                    onClick={() => {
                                                                        formik.setFieldValue("desktopImage", null)
                                                                        setDesktopImage({ DesktopImage: null })
                                                                    }}>
                                                                    {intl.formatMessage({
                                                                        id: "ADMIN_DETAIL.LABELS.REMOVE_IMAGE"
                                                                    })}
                                                                </Button>
                                                            }

                                                            <Button variant="contained" component="label">
                                                                {intl.formatMessage({
                                                                    id: "ADMIN_DETAIL.LABELS.IMAGE"
                                                                })}
                                                                <input
                                                                    id="image-file"
                                                                    name="desktopImage"
                                                                    type="file"
                                                                    accept="image/jpeg, image/jpg, image/png"
                                                                    onChange={event => {
                                                                        if (event.currentTarget && event.currentTarget.files[0]) {
                                                                            formik.setFieldValue("desktopImage", event.currentTarget.files[0])
                                                                            var reader = new FileReader();
                                                                            reader.onloadend = (readerEvt) => {
                                                                                var binaryString = readerEvt.target.result;
                                                                                setDesktopImage({ DesktopImage: binaryString });
                                                                            };
                                                                            reader.readAsDataURL(event.currentTarget.files[0]);
                                                                        }
                                                                    }}
                                                                    hidden
                                                                />
                                                            </Button>
                                                        </div>

                                                        {
                                                            formik.values.desktopImageUrl && !desktopImage.DesktopImage &&
                                                            <div>
                                                                <img src={formik.values.desktopImageUrl}
                                                                    className={classes.desktopImage} />
                                                            </div>
                                                        }
                                                        {
                                                            desktopImage.DesktopImage &&
                                                            <div>
                                                                <img src={desktopImage.DesktopImage}
                                                                    className={classes.desktopImage} />
                                                            </div>
                                                        }
                                                    </div>
                                                }

                                                {
                                                    formik.values.actionType === 1 &&
                                                    <>
                                                        <FormControl
                                                            variant="outlined"
                                                            className={classes.textField}
                                                            fullWidth
                                                        >
                                                            <TextField
                                                                id="url"
                                                                name="url"
                                                                label={intl.formatMessage({
                                                                    id: "ADMIN_DETAIL.LABELS.URL"
                                                                })}
                                                                className={classes.textField}
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                                {...formik.getFieldProps("url")}
                                                                error={checkForError("url")}
                                                                inputProps={{
                                                                    className: classes.textRight
                                                                }}
                                                                dir="ltr"
                                                            />
                                                        </FormControl>
                                                        {renderErrors("url")}
                                                    </>
                                                }
                                                {
                                                    formik.values.actionType === 2 &&
                                                    <div className={classes.textField}>
                                                        <FormControl
                                                            fullWidth={true}
                                                            className={classes.textField}
                                                        >
                                                            <InputLabel htmlFor="role-select">
                                                                <FormattedMessage id="ADMIN_USERS.CREATE_FORM.SUPPLIERS" />
                                                            </InputLabel>
                                                            <Select
                                                                {...formik.getFieldProps("supplier")}
                                                                onChange={event => {
                                                                    formik.setFieldValue("supplier", event.target.value)
                                                                    const cancelToken = axios.CancelToken.source()
                                                                    fetchItems(event.target.value, cancelToken)
                                                                    return () => cancelToken.cancel()
                                                                }}
                                                                input={<FilledInput name="supplier" id="supplier-select" />}
                                                            >
                                                                {
                                                                    suppliersData.data.map(supplier => (
                                                                        <MenuItem
                                                                            key={supplier.SupplierID}
                                                                            value={supplier.SupplierID}
                                                                        >
                                                                            {supplier.Name}
                                                                        </MenuItem>
                                                                    ))
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                        {
                                                            formik.values.supplier &&
                                                            <div>
                                                                <FormControl
                                                                    fullWidth={true}
                                                                    className={classes.textField}
                                                                >
                                                                    <InputLabel htmlFor="role-select">
                                                                        <FormattedMessage id="ADMIN_USERS.CREATE_FORM.ITEMS" />
                                                                    </InputLabel>
                                                                    <Select
                                                                        {...formik.getFieldProps("item")}
                                                                        onChange={event => {
                                                                            formik.setFieldValue("item", event.target.value)
                                                                        }}
                                                                        input={<FilledInput name="item" id="item-select" />}
                                                                    >
                                                                        {
                                                                            itemData.items.map(item => (
                                                                                <MenuItem
                                                                                    key={item.ItemID}
                                                                                    value={item.ItemID}
                                                                                >
                                                                                    {item.Name}
                                                                                </MenuItem>
                                                                            ))
                                                                        }
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                        }

                                                    </div>
                                                }

                                                <FormControl
                                                    fullWidth={true}
                                                    className={classes.textField}
                                                >
                                                    <RadioGroup
                                                        defaultValue={handleGetActive()}
                                                        name="radio-buttons-group"
                                                        onChange={event => {
                                                            if (event.target.value == 0) { formik.setFieldValue("active", true) }
                                                            else formik.setFieldValue("active", false)
                                                        }}
                                                    >
                                                        <FormControlLabel value="0" control={<Radio />} label="active" />
                                                        <FormControlLabel value="1" control={<Radio />} label="Not active" />
                                                    </RadioGroup>
                                                </FormControl>
                                                <FormControl
                                                    fullWidth={true}
                                                    className={classes.textField}
                                                >
                                                    <InputLabel htmlFor="role-select">
                                                        <FormattedMessage id="ADMIN_USERS.CREATE_FORM.AVAILABLE_FIELD.LABEL" />
                                                    </InputLabel>
                                                    <Select
                                                        {...formik.getFieldProps("availableType")}
                                                        onChange={event => {
                                                            formik.setFieldValue("availableType", event.target.value)
                                                        }}
                                                        input={<FilledInput name="availableType" id="active-select" />}
                                                    >
                                                        {availableTypes.map(availableType => (
                                                            <MenuItem
                                                                key={availableType.id}
                                                                value={availableType.id}
                                                            >
                                                                {availableType.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                {

                                                    formik.values.availableType == 0 &&
                                                    <>

                                                        availableFrom
                                                        <div className={classes.timePicker}>


                                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={he}>
                                                                <DatePicker
                                                                    autoOk
                                                                    format="dd/MM/yyyy"
                                                                    value={formik.values.availableFrom}
                                                                    onChange={value => {
                                                                        formik.setFieldValue("availableFrom", value)
                                                                    }}
                                                                    label={intl.formatMessage({
                                                                        id: "DATE_FIELD.LABEL"
                                                                    })}
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                        </div>
                                                        availableTo
                                                        <div className={classes.timePicker}>
                                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={he}>
                                                                <DatePicker
                                                                    autoOk
                                                                    format="dd/MM/yyyy"
                                                                    value={formik.values.availableTo}
                                                                    onChange={value => {
                                                                        formik.setFieldValue("availableTo", value)
                                                                    }}
                                                                    label={intl.formatMessage({
                                                                        id: "DATE_FIELD.LABEL"
                                                                    })}
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                        </div>

                                                    </>
                                                }

                                            </div>
                                        </div>)
                                    }


                                </div>
                            </div>
                        </div>
                    }

                </div>

            </MuiPickersUtilsProvider>
        </>
    )
    if (!bannersData.loading && !editMode.mode) return (<>
        <Typography
            variant="h3"
            id="userAdminTitle"
            style={{ textAlign: "right" }}
        >
            <FormattedMessage id="ADMIN_BANNER.TITLE" />
        </Typography>
        <CreateBannerButton onClick={handleCreateBanner} />
        <BannerList
            bannerData={bannersData}
            onEdit={handleEditBanner}
            onDelete={handleDeleteSubmit}
        />
    </>)
    return (<></>)
}
export default withRouter(injectIntl(BannerAdminPage))
