/* eslint-disable no-restricted-imports */

import React, { useState } from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { FormattedMessage, injectIntl } from "react-intl"
import EnhancedTableHead from "../../Common/EnhancedTableHead"
import { stableSort, getSorting } from "../../Common/tableSortingFunctions"
import { renderStatus } from "../../Common/itemStatusTranslation"
import { Button, FormControlLabel, Checkbox } from "@material-ui/core"
import moment from "moment"
import DishLoader from "../../Common/DishLoader"
import { useStyles } from "../../Common/_styles/elementListStyles"
import { formatDateTime } from "../../Common/momentFunctions"

export function renderBoolean(intl, value) {
    return value
        ? intl.formatMessage({
            id: "OPTION.YES"
        })
        : intl.formatMessage({
            id: "OPTION.NO"
        })
}

const headRows = [
    {
        id: "DeliveryTimeFrom",
        label: <FormattedMessage id="ORDERS_TABLE.LABELS.DELIVERY_DATE_AND_TIME" />,
        disablePadding: true
    },
    {
        id: "SupplierName",
        label: <FormattedMessage id="ORDERS_TABLE.LABELS.SUPPLIER_NAME" />
    },
    {
        id: "Location",
        disablePadding: true,
        label: <FormattedMessage id="ORDERS_TABLE.LABELS.LOCATION_NAME" />
    },
    {
        id: "DepartmentName",
        label: <FormattedMessage id="ORDERS_TABLE.LABELS.DEPARTMENT_NAME" />
    },
    {
        id: "UserName",
        disablePadding: true,
        label: <FormattedMessage id="ORDERS_TABLE.LABELS.USER_NAME" />
    },
    {
        id: "UserMobile",
        disablePadding: true,
        label: <FormattedMessage id="ORDERS_TABLE.LABELS.USER_MOBILE" />
    },
    {
        id: "Quantity",
        label: <FormattedMessage id="ORDERS_TABLE.LABELS.ITEM_QUANTITY" />
    },
    {
        id: "ItemName",
        label: <FormattedMessage id="ORDERS_TABLE.LABELS.ITEM_NAME" />
    },
    {
        id: "OrderCreated",
        disablePadding: true,
        label: <FormattedMessage id="ORDERS_TABLE.LABELS.ORDER_DATE" />
    },
    {
        id: "Options",
        notSortable: true,
        label: <FormattedMessage id="ORDERS_TABLE.LABELS.OPTIONS" />
    },
    {
        id: "Notes",
        disablePadding: true,
        label: <FormattedMessage id="ORDERS_TABLE.LABELS.COMMENTS" />
    },
    {
        id: "Status",
        label: <FormattedMessage id="ORDERS_TABLE.LABELS.STATUS" />,
        onlyForToday: true
    }
]

function OrdersList({ ordersData, markReady, isDateToday, intl }) {
    const classes = useStyles()
    const rows = ordersData.data.Orders.sort((a, b) => (moment('2020-10-10 ' + a.DeliveryTime?.From).toDate() - moment('2020-10-10 ' + b.DeliveryTime?.From).toDate() || a.SupplierName - b.SupplierName))

    const [sortBy, setSortBy] = useState('')
    const [orderBy, setOrderBy] = useState('')

    function handleRequestSort(event, property) {
        const isDesc = sortBy === property && orderBy === "desc"
        setOrderBy(isDesc ? "asc" : "desc")
        setSortBy(property)
    }

    return (
        <Paper className={classes.paper}>
            {ordersData.isLoading && <DishLoader centered />}
            <div className={classes.tableWrapper}>
                <Table aria-labelledby="tableTitle" size="small">
                    <EnhancedTableHead
                        headRows={headRows.filter(row => !row.onlyForToday || isDateToday)}
                        orderBy={orderBy}
                        sortBy={sortBy}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {(orderBy ? stableSort(rows, getSorting(orderBy, sortBy)) : rows).map((row, index) => {
                            const isHighlighted =
                                isDateToday &&
                                ((row.Status === 2 &&
                                    moment(row.CloseCancelTime).isBefore(row.StatusUpdated)) ||
                                    (row.Status === 5 &&
                                        moment(row.CloseEditTime).isBefore(row.StatusUpdated)))
                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row.OrderItemID}
                                    className={isHighlighted ? classes.redRow : ""}
                                >
                                    <TableCell>
                                        <span>
                                            {
                                                moment(row.OrderToDate).format("DD/MM/YYYY ") +
                                                (row.DeliveryTime?.From ?
                                                    moment('2020-10-10 ' + row.DeliveryTime?.From).format("HH:mm") : '')
                                            }
                                        </span>
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="left">
                                        {row.SupplierName}
                                    </TableCell>
                                    <TableCell padding="none">{row.Location.Name}</TableCell>
                                    <TableCell>{row.DepartmentName}</TableCell>
                                    <TableCell padding="none">
                                        {row.UserName}
                                    </TableCell>
                                    <TableCell padding="none">
                                        {row.UserMobile}
                                    </TableCell>
                                    <TableCell>{row.Quantity}</TableCell>
                                    <TableCell>{row.ItemName}</TableCell>
                                    <TableCell padding="none">
                                        {formatDateTime(row.OrderCreated)}
                                    </TableCell>
                                    <TableCell>
                                        <div className="d-flex flex-row">
                                            {row.OptionGroups &&
                                                row.OptionGroups.map((optionGroup, index) => (
                                                    <div
                                                        key={optionGroup.GroupName}
                                                        className={`px-1 ${index !== row.OptionGroups.length - 1
                                                                ? "border-right"
                                                                : ""
                                                            }`}
                                                    >
                                                        <div>
                                                            <b>{optionGroup.GroupName}</b>:
                                                        </div>
                                                        <div>{optionGroup.SelectedOptios}</div>
                                                    </div>
                                                ))}
                                        </div>
                                    </TableCell>
                                    <TableCell padding="none">{row.Notes}</TableCell>
                                    {isDateToday && (
                                        <TableCell padding="none">
                                            {renderStatus(intl, row.Status)}
                                        </TableCell>
                                    )}
                                    

                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </div>
        </Paper>
    )
}

export default injectIntl(OrdersList)
